// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Media Query
$bp-small: "screen and (min-width: 20em)"; //320px
$bp-sp: "screen and (min-width: 30em)"; //480px
$bp-tab: "screen and (min-width: 48em)"; //768px;
$bp-pc: "screen and (min-width: 67em)"; //1072px;
$bp-large: "screen and (min-width: 79em)"; //1264px;

//Font
$font-normal: 100% / 1.5 #{"rem"};
$font-large: 100% / 2 #{"rem"};

//Margin
$block-margin: 30px;
$block-margin-small: 15px;
$block-margin-large: 45px;

//@import "variables";

//Color

$color-white: #ffffff;
$color-black: #000000;
$color-grey: #808080;
$color-light-grey: #eee;
$color-red: #ff0000;
$color-green: #00ff00;
$color-blue: #0000ff;
$color-yellow: #ffff00;
$color-nav-hover: #999999;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$dark-glay: #3a3a3a;
$glay: #9b9b9b;
$light_glay: #f8f9fa;
$light_blue: #dfecff;
$ultra_light_blue: #ecf4ff;

$color-primary: #036eb8;
$color-secondary: #29556f;
$color-thirdry: #d8e7f7;
$color-fourthry: #cedee7;
//$color-fifthry: #eef3f6;
$color-fifthry: #f2f8ff;
$color-sixry: #f9fcff;

$color-status: #e73962;

//Space
$space_small: 15px;
$space_middle: 45px;
$space_large: 60px;

//text
$line-height: 2.5rem;

$img-accept: 56.25%; //画像の縦横比

//グリッド画像
$culumns: (
    1: 100%,
    2: 45%,
    3: 30%,
    4: 23%,
);

$duration: 0.3s;

@mixin img-grid($culumn-sp: 2, $culumn-tab: 3, $culumn-pc: 4) {
    ul {
        display: flex;
        flex-direction: culumn;
        justify-content: space-between;
        flex-wrap: wrap;
        @media #{$bp-tab} {
            flex-direction: row;
        }

        &::before {
            content: "";
            display: block;
            width: map-get($culumns, $culumn-sp);
            order: 1;
        }
        &::after {
            content: "";
            display: block;
            width: map-get($culumns, $culumn-sp);
        }
        @media #{$bp-tab} {
            &::before {
                width: map-get($culumns, $culumn-tab);
            }
            &::after {
                width: map-get($culumns, $culumn-tab);
            }
        }
        @media #{$bp-pc} {
            &::before {
                width: map-get($culumns, $culumn-pc);
            }
            &::after {
                width: map-get($culumns, $culumn-pc);
            }
        }

        li {
            width: map-get($culumns, $culumn-sp);
            margin: 0.5rem 0;
            overflow: hidden;
            position: relative;
            @media #{$bp-sp} {
                margin: 0.7rem 0;
            }
            @media #{$bp-tab} {
                width: map-get($culumns, $culumn-tab);
            }
            @media #{$bp-pc} {
                width: map-get($culumns, $culumn-pc);
            }
            &:before {
                content: "";
                display: block;
                padding-top: $img-accept;
            }

            a {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: $duration;

                &:hover {
                    transform: scale(1.1);
                    img {
                        opacity: 0.75;
                    }
                }
                img {
                    opacity: 1;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition-duration: $duration;
                }
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

//Welcome
$class_padding: 20px;
$class_border-color: #999999;
$border-thickness: 1px;
$border-hover-thickness: 1px;
$border-selected-thickness: 1px;
$transition-speed: 0.2s;
$checkbox_position: 20px 18px;
$checkbox_size: 24px 24px;
$border-radius: 0px;
$classes-border-width: 1px;
$border-timetable: #{$color-secondary} 1px solid;
$border-radius-timetable: 0px;
$bg-timetable: #eee;
