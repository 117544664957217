$border-radius: 0px;
$border-width: 1px;

.selected_class {
    margin-bottom: $space_middle;
}

.box_confirm {
    border-radius: 0px;
    border: #{$border-width} solid #dee2e6;
    border-bottom: none;
    .row {
        &:last-of-type {
            border-bottom-left-radius: calc(
                #{$border-radius} - #{$border-width}
            );
            border-bottom-right-radius: calc(
                #{$border-radius} - #{$border-width}
            );
            .box_confirm_left {
                border-bottom-left-radius: calc(
                    #{$border-radius} - #{$border-width}
                );
            }
            .box_confirm_right {
                border-bottom-right-radius: calc(
                    #{$border-radius} - #{$border-width}
                );
            }
        }
    }
}
