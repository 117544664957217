body {
    &.welcome {
        .bottom {
            .application {
                /* 大きく */
                .departments {
                    .form-check-input {
                        //width: 1em !important;
                        //height: 1.5em !important;
                        transform: scale(1.5);
                        margin-left: 1em;
                        margin-right: 1em;
                        margin-bottom: 1.5em;
                    }
                }
            }
        }
    }
}
