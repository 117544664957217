$button_padding_parent: 1em 0em 1em 1em;
$button_padding_child: 1em 0em 1em 1.5em;
$button_padding_grandchild: 1em 0em 1em 2em;

$button_color_collapsed: #FF000;
$button_color_active: #6c757d;

body {
    &.admin {
        nav#nav {
            position: fixed;
            overflow:auto;
            top:56px;
            width: $admin_nav_width;
            margin-right: 30px;
            height: calc(100vh - 56px);
            z-index: 1000;
            border-right:1px solid #{$color-light-grey};




            ul,
            li,
            .card-header {
                padding: 0;
                margin: 0;
            }

            .btn {
                //border-left: none;
                //border-top: none;
                //border-right: none;
                //border-radius: 0;
                text-align: left;
            }

            a,
            button {
                width: 100%;
                text-align: left;
            }
            a {
                &.disabled {
                    color: $glay !important;
                }
            }

            .show{
                visibility:visible !important;
            }

            .accordion-button-single {
                &::after {
                    content: "";
                    background-image: none !important;
                }
            }

            .card-header {
                & > button {
                    position: relative;
                    &::after {
                        position: absolute;
                        display: inline-block;
                        content: "";
                        width: 1rem;
                        height: 1rem;
                        background-repeat: no-repeat;
                        background-size: 1rem 1rem;
                        right: 1em;
                        top: 1.2em;
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-up' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/></svg>");
                    }
                    &.collapsed {
                        &::after {
                            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                        }
                    }
                }
            }

            & > ul {

                & > li {
                    border-radius: 0;
                    //border: none;
                    min-width: 200px;
                    padding: 0;


                    //list-style: none;
                    //display: block;
                    //border: 1px solid #000;
                    //padding: 0.5em;
                    //text-align: center;
                    & > a,
                    & > button {
                        font-weight: bold;
                        padding: #{$button_padding_parent};
                    }

                    & > .card-header {
                        & > a,
                        & > button {
                            font-weight: bold;
                            padding: #{$button_padding_parent};
                        }
                    }

                    & > .child {
                        & > ul {
                            & > li {
                                & > a,
                                & > button {
                                    padding: #{$button_padding_child};
                                }
                                & > .card-header {
                                    & > a,
                                    & > button {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                        padding: #{$button_padding_child};
                                    }
                                }
                                & > a{

                                    &.btn.btn-light{
                                        border-radius: 0;
                                        border: none;
                                        &.active{
                                            color:#0c63e4;
                                            background-color:#e7f1ff;
                                            border-radius:0;
                                            border:none;
                                        }
                                    }
                                }
                                & > .grandchild {
                                    //background-color: #f00;
                                    & > ul {
                                        & > li {
                                            //display: block;
                                            list-style: none;
                                            & > .card-header {
                                            }
                                            & > a,
                                            & > button {
                                                color: #212529;
                                                border-bottom: 1px solid
                                                    rgba(0, 0, 0, 0.125);
                                                text-decoration: none;
                                                width: 100%;
                                                height: 100%;
                                                padding: #{$button_padding_grandchild};
                                                display: block;
                                            }
                                            & > a{
                                                &.active{
                                                    color:#0c63e4;
                                                    background-color:#e7f1ff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
