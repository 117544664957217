.prose {
    ol,
    ul {
        margin-left: 0em;
        padding-left: 0;
        li {
            padding-left: 1.2em;
        }
    }
}
