body {
    &.admin {
        .navbar {
            position: fixed;
            top:0;
            width: 100%;
            z-index: 1001;
        }
    }
}
