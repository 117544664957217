body{
    &.admin_classes{

        .classes_image_preview{
            img{
                width: 150px;
            }
        }
        .classes_list{
            img{
                width: 200px;
            }
        }
    }
}
