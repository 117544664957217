//Form
.signin {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 780px;
    background-color: #f5f5f5;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    max-width: 600px;
    padding: 15px;
    margin: 0 auto;
    background-color: #f5f5f5;
    h1 {
        text-align: center;
    }
    .checkbox {
        font-weight: 400;
    }
    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-control:focus {
        z-index: 2;
    }
    input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    button {
    }
}

//必須項目
label {
    &.require:after {
        //content: "<span class='badge badge-danger"'></span>";
        //content: "必須";
    }
}

span {
    &.badge,
    &.badge-danger,
    &.require {
        //content:"必須";
        //display: block;
        //width: 100px;
        //height: 100px;
    }
}

//表示件数
.list_number {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    label {
        margin-bottom: 0;
        margin-right: 15px;
    }
    span {
        margin-left: 15px;
    }
}

//チェックボックスの装飾
/* 本来のチェックボックスを非表示 */
.checkbox {
    display: flex;
    padding-left: 15px;

    input[type="checkbox"] {
        display: none;
        align-items: center;
        justify-content: flex-start;
        & + label {
            cursor: pointer;
            display: inline-block;
            box-sizing: content-box;
            position: relative;
            width: 60px;
            height: 30px;
            background-color: #fff;
            border: 2px solid #ccc;
            border-radius: 30px;
        }

        &:checked + label {
            background-color: $color-primary;
            border-color: $color-primary;
        }

        & + label:before {
            content: "";
            position: absolute;
            display: block;
            width: 30px;
            height: 30px; /* widthと同じ 上のラベルの高さに揃える */
            left: 0;
            top: 0;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 2px 2px 4px gray;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }

        &:checked + label:before {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            box-shadow: none;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
    }

    span {
        margin-left: 20px;
        line-height: 30px;
    }
}

//必須項目
/*
.require {
    margin-left: 1em;
    color: #fff;
    padding: 0.2rem 0rem;
    font-size: 0.5rem;
    width: 2.5rem;
    display: inline-block;

    background: #f00;
    &:before {
        text-align: center;
        content: "必須";
        display: block;
    }
}
*/
