footer {
    background: #000;
    padding: 30px;
    color: #fff;
    div {
        margin: 0 auto;
        text-align: center;
        width: 300px;
    }
    & > p {
        text-align: center;
    }
    a {
        color: $color-white;
        //text-decoration: none;
        &:hover {
            color: $color-white;
            text-decoration: underline;
        }
    }
}
