html,body{
    height: 100%;
}
main{
    width:100%;

}


.slick-slider{
    margin: 0;
    padding: 0;
}

.container{
    &.noresize{
        width: 100%;
        max-width: none;
        min-width: 0;
        padding: 0;
        margin: 0;
    }
}

.no_border{
    border: none;
}



.return{
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

@mixin form-singin {
    background-color: $bg-color-3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}





.bi::before {
    //width: 16px;
    //height: 16px;
    display: inline-block;
    //font-size: 16px;
    //content: "";
    background-image: url("data:image/svg+xml,");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}












