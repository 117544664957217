body {
    $mypage-nav-border-color: #dee2e6;
    &.mypage {
        .nav {
            width: 100%;
            display: flex;
            justify-content: center;
            border-bottom: solid 1px #{$mypage-nav-border-color};
            border-left: solid 1px #{$mypage-nav-border-color};

            @media #{$bp-tab} {
                justify-content: center;
                border-bottom: none;
                border-left: none;
            }
            a {
                width: 50%;
                display: flex;
                flex-direction: column;
                border-radius: 0em;
                justify-content: center;
                border-bottom: none;
                border-left: none;
                border-top: solid 1px #{$mypage-nav-border-color};
                border-right: solid 1px #{$mypage-nav-border-color};
                color: $color-primary;

                @media #{$bp-tab} {
                    border: solid 1px #{$mypage-nav-border-color};
                    border-bottom: solid 1px #{$mypage-nav-border-color};
                    border-left: solid 1px #{$mypage-nav-border-color};
                    width: 25%;
                    width: auto;
                    border-radius: inherit;
                }
                &.active {
                    background-color: $color-primary;
                    color: $color-white;
                }

                &:hover {
                    background-color: $color-primary;
                    color: $color-white;
                }

                i {
                    font-size: 32px;
                }
                span {
                    font-size: 0.7em;
                }
            }

            //ナビゲーションが５個の場合の一時的な処置
            .nav-mypage-0{
                width:100%;
                @media #{$bp-tab} {
                    width:auto;
                }
            }
        }
    }
}
