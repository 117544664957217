body {
    &.beginner{
        @include form-singin;
        .content{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 500px;

        }
        .form-signin {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width:420px;
            width: 100%;


            .form-group{
                display: flex;
                flex-direction: column;
                justify-content: center;
                label{
                    //width: 100%;
                    //display: block;
                }
                button{
                    margin-top: 30px;
                }
            }


        }



    }
}
