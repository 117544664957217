$width_right: 70px;
body {
    &.mypage {
        &.mypage_information_detail {
            img {
                max-width: 300px;
            }
        }
        .information_list {
            & > .header {
                display: none;
                @media #{$bp-tab} {
                    display: flex;
                }

                .left {
                    width: calc(100% - #{$width_right} - 10px);
                    text-align: left;
                }
                .right {
                    width: $width_right;
                }
            }
            .classes {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                @media #{$bp-tab} {
                }

                .left {
                    width: calc(100% - #{$width_right} - 10px);
                    & > .outline {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        @media #{$bp-tab} {
                            flex-direction: row;
                        }
                        div::before {
                            text-align: left;
                            //margin-right: 0.5em;
                            width: 4em;
                            @media #{$bp-tab} {
                                content: none !important;
                                width: 0;
                            }
                        }

                        .id {
                            &::before {
                                content: "申込番号:";
                            }
                        }
                        .date {
                            &::before {
                                content: "申込日:";
                            }
                        }
                        .schedule {
                            br {
                                /*
                                display: none;
                                @media #{$bp-tab} {
                                    display: inline;
                                }
                                */
                            }
                            &::before {
                                //width: 8em;
                                content: "実施日:";
                            }
                        }
                        .title {
                            &::before {
                                //width: 4em;
                                content: "授業名:";
                            }
                        }
                    }
                }

                .right {
                    width: $width_right;
                }
            }
        }
    }
}
