body{
    &.admin.schedule{

        &.master{
            img{
                position: relative;
                width: 100%;
            }
        }

        &.new,&.edit{
            img{
                max-height: 80px;
            }
            .image_preview{
                max-height: 100px;
            }
        }
    }
}

