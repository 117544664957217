header {
    margin-top: 15px;
    margin-bottom: 15px;

    .logo {
        background-image: url(#{$asset_url} + "img/totop.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 164px;
        //height: 26px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        background-position: center center;

        /*
        @media #{$bp-tab} {
            background-image: url("/img/logo_pc.svg");
            width: 286px;
            height: 52px;
        }
        */
    }

    .login {
        width: 60px;
        height: 60px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 24px;
        //border-radius: 0;
        &:hover {
            text-decoration: none;
        }
        i {
            line-height: 0;
            font-size: 12px;
        }
        span {
            font-size: 0.3em;
            @media #{$bp-tab} {
                display: inline;
            }
        }
    }
}
