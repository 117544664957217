//Text Colors
$text-color-1: #000;
$text-color-1-deactive: #ccc;
$hover-color-1: #3490dc;

//Border Colors
$border-1: #ccc solid 1px;
$border-1-hover: #bbb solid 3px;
$border-seleced-color: #0d6efd;

//Background Color
$bg-color-3: #f5f5f5;

main {
    &.container {
        margin-bottom: 30px;
    }
}
