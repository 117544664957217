$class_padding: 20px;
$class_border-color: #999999;
$border-thickness: 1px;
$border-hover-thickness: 1px;
$border-selected-thickness: 1px;
$transition-speed: 0.2s;
$checkbox_position: 20px 20px;
$checkbox_size: 24px 24px;
$border-radius: 0px;
$classes-border-width: 1px;
$border-timetable: #{$color-secondary} 1px solid;
$border-radius-timetable: 0px;
$bg-timetable: #eee;

body {
    &.welcome {
        .mv {
            opacity: 0;
            transition: opacity 1s;
            margin-bottom: #{$block-margin};
            ul {
                li {
                    display: block;
                    width: 100%;
                    padding-top: 33%;
                    position: relative;

                    a {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        object-fit: cover;
                        background-size: cover;
                        background-position: center;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }

        .bottom {
            .guide {
                display: flex;
                justify-content: center;
                flex-direction: column;
                @media #{$bp-tab} {
                    flex-direction: row;
                }
                a {
                    font-size: 36px;
                    margin: 15px 0;
                    @media #{$bp-tab} {
                        margin: 0 15px;
                    }
                }
                .begginer {
                }
            }

            .news {
                margin: #{$block-margin} auto;
                dl {
                    dt {
                    }
                    dd {
                        div {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            overflow: hidden;
                        }
                    }
                }
                ol {
                    li {
                    }
                }

                .card-footer {
                    display: flex;
                    justify-content: right;
                    a {
                        display: block;
                        margin-left: auto;
                    }
                }
                .btn-news {
                    .bi::before {
                        display: inline-block;
                        content: "";
                        width: 1rem;
                        height: 1rem;
                        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' clip-rule='evenodd'/></svg>");
                        background-repeat: no-repeat;
                        background-size: 1rem 1rem;
                    }
                    &.collapsed {
                        .bi::before {
                            background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' clip-rule='evenodd'/></svg>");
                        }
                    }
                }
            }

            .parent_program {
                margin: #{$block-margin} auto;

                th,
                td {
                    display: block;
                    width: 100%;
                }

                @media #{$bp-tab} {
                    th,
                    td {
                        display: table-cell;
                        width: auto;
                    }
                }
            }
        }

        //授業一覧
        .class-group {
            h2,
            h3 {
                text-align: center;
            }

            .classes {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }

        .schedule_butttons {
            margin: $block-margin;
            display: flex;
            a {
                //margin: ;
                color: white;
            }
        }

        #not_auth {
            width: 100%;
        }

        /* 表示/非表示はvueで制御するので最初から表示状態にする */
        .modal {
            display: block;
            background-color: rgba;
            //z-index: 10000;
            &.fade {
                opacity: 1;
            }

            //z-index: 10001;
        }


        /* vueのtransitionを使わないなら不要 */
        .overlay {
            //z-index: 1000;
        }

        .fade-enter-active,
        .fade-leave-active {
            transition: opacity 0.15s;
            z-index: 1000;
            position: fixed;
        }
        .fade-enter-to {
            //z-index: 1000;
        }
        .fade-enter,
        .fade-leave-to {
            opacity: 0;
            z-index: 1000;
            position: fixed;
        }

        .modal-dialog {
            //z-index: 1001;
        }

        .modal-backdrop {
            //z-index: 1000;
        }
    }
}
