.form-check-input {
    clear: left;
}

//Checkbox(Normal)
.form-check-input-normal {
    width: 1em !important;
    height: 1.5em !important;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1.5em;
}

//Chekcbox
.form-check {
    display: flex !important;
    align-items: center !important;
    margin-right: 1em;
    //margin-left: 0em;
    //padding-left: 0;

    input[type="checkbox"] {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl
//Checkbox(Switch)
@mixin switch($res: "sm") {
    $index: 1rem;
    $mainVal: 1rem;

    @if $res == "md" {
        $index: 2rem;
        $mainVal: 1.5rem;
    } @else if $res == "lg" {
        $index: 3rem;
        $mainVal: 2rem;
    } @else if $res == "xl" {
        $index: 4rem;
        $mainVal: 2.5rem;
    }

    //margin-bottom: $index / 2; /* JUST FOR STYLING PURPOSE */

    .form-check-input {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }
}

.form-switch.form-switch-sm {
    @include switch();
}

.form-switch.form-switch-md {
    @include switch("md");
}

.form-switch.form-switch-lg {
    @include switch("lg");
}

.form-switch.form-switch-xl {
    @include switch("xl");
}

.form-switch {
    input[type="checkbox"]:checked {
        background-size: auto;
    }
}

//

//Buttons
.btn-light-grey {
    color: #000 !important;
    background-color: rgba(0, 0, 0, 0.01) !important;
    border-color: rgba(0, 0, 0, 0.01) !important;

    &:hover {
        color: #000 !important;
        background-color: rgba(0, 0, 0, 0.035) !important;
        border-color: rgba(0, 0, 0, 0.035) !important;
    }
}

.btn-grey {
    color: #000 !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-color: rgba(0, 0, 0, 0.05) !important;

    &:hover {
        color: #000 !important;
        background-color: rgba(0, 0, 0, 0.075) !important;
        border-color: rgba(0, 0, 0, 0.075) !important;
    }
}

//Background color
.bg-light-grey {
    background-color: rgba(0, 0, 0, 0.01) !important;
}
.bg-grey {
    background-color: rgba(0, 0, 0, 0.05) !important;
}


//Modal
//Okボタンが押されたらボタンを無効にする。
.modal {
    &.ok-clicked{
        &::after{
            position:absolute;
            left: 0;
            top: 0;
            content: ' ';
            width: 100%;
            height: 100%;
            background-color: #F00;
        }
    }
    .modal-footer{
        position: relative;
        &.ok-clicked{
            &::after{
                position:absolute;
                left: 0;
                top: 0;
                content: ' ';
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0;
            }

            button[type="submit"]{
                position: relative;
                &::after{

                    content: ' ';
                    //background-color: #F00 !important;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;


                }
                //background-color: #F00 !important;
                &:hover{
                    //background-color: #F00 !important;
                }
            }

        }

    }

}



.modal-disable-cover{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255, 0.5);
}

.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background: #0d6efd;
    background: -moz-linear-gradient(left, #0d6efd 10%, rgba(13,110,253, 0) 42%);
    background: -webkit-linear-gradient(left, #0d6efd 10%, rgba(13,110,253, 0) 42%);
    background: -o-linear-gradient(left, #0d6efd 10%, rgba(13,110,253, 0) 42%);
    background: -ms-linear-gradient(left, #0d6efd 10%, rgba(13,110,253, 0) 42%);
    background: linear-gradient(to right, #0d6efd 10%, rgba(13,110,253, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #0d6efd;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: rgba(255,255,255, 1);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


  //アコーディオンメニューの修正
  .collapse{
    &.show{
        visibility: visible;
    }
  }
