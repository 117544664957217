body {
    &.login{
        @include form-singin;
        main{
            display: flex;
            justify-content: center;
        }

        .form-login{
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: 420px;
            .form-label-group {
                position: relative;
                margin-bottom: 1rem;
            }

            .register{
                .btn{
                    font-size: 24px;
                }

                margin-top: 3em;
                display: flex;
                justify-content: center;
            }
        }

    }
}
