body {
    &.admin {
        main {
            margin: 0px auto;
            display: flex;

            & > .nav {
                width: $admin_nav_width;
            }
            & > .content {
                width: calc(100vw - #{$admin_nav_width} - #{$space_middle});
                margin-left: calc(#{$admin_nav_width} + #{$space_middle});
                padding-right: $space_middle;
                margin-top: 100px;
                margin-bottom: 150px;
                @media #{$bp-pc} {

                }
                @media #{$bp-large} {
                    margin-bottom: 100px;
                }

            }
            .pagenation {
                position: fixed;
                bottom: 0;
                right: 0;
                width: calc(100vw - #{$admin_nav_width});
                z-index: 1000;
                p{
                    margin: 0 20px;
                }


            }
        }

        .form-login {
            min-width: 400px;
            margin: auto;
        }
        .form-half-group {
            label {
                display: block;
                width: 100%;
            }
            display: flex;
            .form-half {
                width: 50%;
            }
        }

    }

    //アップロードボタン
    .image_upload {
        display: inline-block;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        background: $color-primary;
        color: #fff;
        text-align: center;
        padding: 0.375rem 0.75rem;
        line-height: 30px;
        //width:180px;
        cursor: pointer;
        &:hover {
            background: #0069d9;
        }
        input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }
    }

    .uploadValue {
        display: none;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 3px;
        padding: 3px;
        color: #ffffff;
    }

    //ページ下部の更新・保存ボタン、ページネーション
    .bottom-nav {
        margin-left: #{$admin_nav_width};
        width: calc(100% - $admin_nav_width);
    }

}
